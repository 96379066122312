<template>
    <div v-if="$can('access','PdfCodeVersion1')">
        <v-list-item class="font-sm" @click="viewPdf(purchaseOrderId)" v-if="updateMode && listMode"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ selfList ? $t('message.pdf') : $t('message.po') }}</v-list-item>
        <v-btn
            class="ml-2 lighten-2"
            small
            v-if="updateMode && !listMode"
            @click.stop="viewPdf(purchaseOrderId)"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
    </div>
    <div v-else>
        <v-btn
            :loading="loading.purchaseOrder"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printPurchaseOrder()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printPurchaseOrder()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.purchaseOrder"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.po') }}</span>
        </v-list-item>
        <PrintPurchaseOrderBlob
            :purchase-order-id="purchaseOrderId"
            :key="updatePurchaseOrderKey"
            @pdf-uploading="purchaseOrderUploading"
            @pdf-uploaded="purchaseOrderUploaded"
            @pdf-not-uploaded="purchaseOrderNotUploaded"
            v-if="hasPurchaseOrder"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";
import {v4 as uuidv4} from "uuid";

const PrintPurchaseOrderBlob = () => import("Views/v1/purchaseorders/PrintPurchaseOrderBlob");
export default {
    name: "PrintPurchaseOrderButton",
    props: ['purchaseOrderId','purchaseOrderTitle','updateMode','listMode','selfList'],
    components: {PrintPurchaseOrderBlob},
    data() {
        return {
            currentPurchaseOrderPdf: null,
            hasPurchaseOrder: false,
            loading: {
                purchaseOrder: false,
            },
            updatePurchaseOrderKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/purchaseorders/" + this.purchaseOrderId, {
                        params: {
                            fields: [
                                'purchaseorders.location AS purchaseorders__location',
                                'purchaseorders.contract_id AS purchaseorders__contract_id',
                                'contracts.ms_id AS purchaseorders__contracts__ms_id'
                            ]
                        }
                    })
                    .then(response => {
                        const purchaseOrder = response.data.data[0]
                        if(purchaseOrder){
                            this.currentPurchaseOrderPdf = purchaseOrder.Purchaseorder.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        purchaseOrderNotUploaded() {
            this.loading.purchaseOrder = false
            this.hasPurchaseOrder = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        purchaseOrderUploaded(url) {
            this.currentPurchaseOrderPdf = url
            this.loading.purchaseOrder = false
            this.viewFile(this.currentPurchaseOrderPdf)
            this.hasPurchaseOrder = false
        },
        purchaseOrderUploading() {
            this.loading.purchaseOrder = true
        },
        printPurchaseOrder() {
            this.loading.purchaseOrder = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentPurchaseOrderPdf != null){
                        this.viewFile(this.currentPurchaseOrderPdf)
                            .then(() => this.loading.purchaseOrder = false)
                            .catch(() => this.loading.purchaseOrder = false)
                    } else {
                        this.hasPurchaseOrder = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.purchaseOrder = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        },
        viewPdf (purchaseOrderId) {
            let document  = encodeURIComponent(this.purchaseOrderTitle + ' PO')
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/purchaseorders/print/'
                + purchaseOrderId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    }
}
</script>

<style scoped>

</style>